import { Transition } from '@headlessui/react';
import Link from 'components/links/Link';
import React, { Fragment } from 'react';

import pages from '../config/pages';
import Button from './buttons/Button';

function CookieNotice({ onAccept, show }) {
    return (
        <Transition
            as={Fragment}
            show={show}
            enter="transition transform ease-out duration-300"
            enterFrom="transform opacity-0 sm:scale-75"
            enterTo="transform opacity-100 sm:scale-100"
            leave="transform ease-in duration-100"
            leaveFrom="opacity-100 sm:scale-100"
            leaveTo="opacity-0 sm:scale-75"
        >
            <div className="w-full bottom-0 left-0 md:max-w-md rounded-xl rounded-b-none md:rounded-3xl border-2 border-brand shadow-2xl bg-white z-50 fixed md:bottom-3 md:right-4 md:left-auto">
                <div className="p-3 md:p-4">
                    <h4 className="text-base text-brand font-serif md:text-xl uppercase mb-2">Uporabljamo piškotke</h4>
                    <p className="text-sm">
                        Spletna platforma KZV za svoje delovanje uporablja piškotke in piškotkom podobne tehnologije, ki
                        nam omogočajo zbiranje informacij o vaši uporabi naše spletne platforme, vaši napravi in uporabi
                        spletnega brskalnika. Več o uporabi piškotkov si lahko preberete v naši{' '}
                        <Link to={pages.cookies.url} styles="underline">
                            politiki piškotkov.
                        </Link>
                    </p>
                    <div className="flex justify-between mt-4">
                        <Button variant="text" color="secondary" link="/piskotki#nastavitve" outerStyles="text-sm">
                            Nastavitve
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onAccept} outerStyles="text-sm">
                            Strinjam se z uporabo piškotkov
                        </Button>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default CookieNotice;
